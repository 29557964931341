export const jaTranslation = {
  errorLoading: () => "結果が読み込まれませんでした",
  inputTooLong: ({ input, maximum }) =>
    `${input.length - maximum} 文字を削除してください`,
  inputTooShort: ({ minimum }) => `${minimum}文字以上入力してください`,
  loadingMore: () => "読み込み中…",
  maximumSelected: ({ maximum }) => `${maximum} 件しか選択できません`,
  noResults: () => "該当するものが見つかりません",
  searching: () => "検索しています…",
  removeAllItems: () => "すべてのアイテムを削除",
}
